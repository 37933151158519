<template>
	<div>
		<template>
			<v-container>
				<SettingsGear :showIcon="true" />
				<div style="height: 15vh">
					<div
						style="
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 2vh;
            "
					>
						<img
							src="@/assets/logos/dime_logo_gold.png"
							style="height: 7em"
						/>
					</div>
					<div
						style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-content: flex-end;
            "
					></div>
				</div>
				<div style="height: 66vh; overflow: hidden">
					<div style="margin-top: 1vh"></div>
					<div class="device-title text-uppercase">my devices</div>
					<div v-if="deviceList.length > 0" class="device-list">
						<div
							v-for="(item, index) in deviceList"
							:key="index"
							class="device-item"
						>
							<span class="device-item-left">·</span>
							<div
								class="device-item-name text-white"
								@click="onClickDeviceName(item)"
							>
								{{ item.deviceName }}
							</div>
							<div
								class="device-item-button text-uppercase text-center text-white"
								@click="onClickUnbound(item, index)"
							>
								Unpair
							</div>
						</div>
					</div>
					<div v-else class="device-list-none">
						You are not currently bound to any devices.
					</div>
				</div>
				<UpdateDevice
					:show="updateDeviceDialog"
					:device="currentDevice"
					@updialog-close="closeUpdateDeviceDialog"
					@updialog-confirm="confirmUpdateDeviceDialog"
				/>
				<Footer />
			</v-container>
		</template>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import device from '@/api/device'
import bluetoothRepository from '../bluetooth/BluetoothRepository'
import { Toast } from 'vant'

export default {
	name: 'deviceList',
	data() {
		return {
			deviceList: [],
			currentDevice: null,
			updateDeviceDialog: false,
		}
	},
	created() {
		this.selectMyDevice()
	},
	methods: {
		...mapActions(['_setDeviceName']),
		// 查询我的设备列表
		selectMyDevice() {
			this.showLoading('Loading...')
			device.selectMyDevices().then((data) => {
				this.hideLoading()
				this.deviceList = data
			})
		},
		// 解绑设备
		async onClickUnbound(item, index) {
			let params = { id: item.id } //解绑设备参数
			this.showLoading('Loading...')
			try {
				let data = await device.untieDevice(params)
				if (bluetoothRepository.isConnected()) {
					if (
						item.deviceNumber ===
						this.$store.state.bluetooth.macAddress
					) {
						await bluetoothRepository.writeEmptyUserIDFlag()
						bluetoothRepository.disconnect()
					}
				}
				this.deviceList.splice(index, 1)
			} catch (e) {
			} finally {
			}
		},
		onClickDeviceName(item) {
			this.currentDevice = item
			this.updateDeviceDialog = true
		},
		closeUpdateDeviceDialog(data) {
			this.updateDeviceDialog = data
		},
		confirmUpdateDeviceDialog(event) {
			device.selectMyDevices().then((data) => {
				this.deviceList = data
			})
			this._setDeviceName(event)
		},
	},
	computed: {
		...mapGetters(['getMainColor', 'getAccentColor']),
	},
}
</script>

<style lang="less" scoped>
.device-title {
	color: #ffffff;
	font-size: 2.5vh;
	text-align: center;
	font-weight: bold;
}

.device-list {
	height: 61vh;
	overflow-y: auto;
}

.device-list-none {
	color: #ffffff;
	font-size: 2.5vh;
	text-align: center;
	font-weight: bold;
	margin-top: 40%;
}

.device-item {
	display: flex;
	align-items: center;
	line-height: 4vh;
	margin-top: 1.5vh;

	.device-item-left {
		color: #ffab00;
		font-size: 12vh;
	}

	.device-item-name {
		font-size: 2.2vh;
		font-weight: bold;
	}

	.device-item-button {
		margin-left: auto;
		border-radius: 30px;
		border: 2px solid rgb(255, 171, 0);
		font-size: 2vh;
		font-weight: bold;
		padding: 0.3vh 4vh;
	}

	.device-item-button:active {
		background: #ffab00;
	}
}
</style>
